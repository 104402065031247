<template>
    <div class="tableListData">
      <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData" ></list-search>
      <global-table ref="supNew" highlight-current-row @SelectionChange="handleCurrentChange" :tableField="tableField" :tableData="tableData"></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'
import { tableField, tableData, SearchList, SearchData } from './SupchoiceDialog'
export default {
  components: Component.components,
  name: 'SupchoiceDialog',
  data () {
    return {
      supChangeData: {},
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData
    }
  },
  created () {
    this.requestSupplierOption()
  },
  methods: {
    // 获取供应商列表
    requestSupplierOption (data) {
      const obj = {
        ...data
      }
      requestForm('/api/supplier/basicData/getForSelect', 'post', obj).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data
          this.tableData.forEach(item => {
            item.erpCode = ~~item.erpCode
          })
        }
      })
    },
    // 获取选中供应商
    handleCurrentChange (val) {
      this.supChangeData = val
      console.log('勾选', this.supChangeData)
    },
    // 查询
    onSearch (data) {
      this.requestSupplierOption(data)
    },
    // 清空
    clearSearch (data) {
      this.requestSupplierOption(data)
    }
  }
}
</script>
<style scoped lang="scss">
.tableListData{
  .tableNav {
    border-bottom:none;
  }
}
</style>
