export var SearchList = [
  { labe: '供应商', code: 'supplier', type: 'input', placeholder: '编码/名称' }

]
export var SearchData = {
  supplier: ''
}

export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '供应商编码', code: 'erpCode', type: 'input', width: '40%' },
  { label: '供应商名称', code: 'name', type: 'input', width: '' }
]

export var tableData = []
